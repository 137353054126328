import { EstadisticasEvaluacionRecomendadaComponent } from "@puntaje/puntaje/core"
import {
    EvaluacionAdminComponent,
    EvaluacionesMasivasComponent,
    ListaEvaluacionesRecomendadasComponent,
    CrearEvaluacionRecomendadaComponent,
    EditarEvaluacionRecomendadaComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

import { TokenGuard } from "@puntaje/puntaje/services"

export const routes: Routes = [
    {
        path: "evaluaciones_masivas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesMasivasComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "evaluaciones/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionAdminComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "evaluacion_sugeridas/:id/estadisticas",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EstadisticasEvaluacionRecomendadaComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "evaluacion_sugeridas",
        component: LoggedLayoutComponent,
        children: [
            { path: "", component: ListaEvaluacionesRecomendadasComponent, canActivate: [TokenGuard, AuthGuard] }
        ]
    },
    {
        path: "evaluacion_sugeridas/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: CrearEvaluacionRecomendadaComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluacion_sugeridas/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EditarEvaluacionRecomendadaComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "admin_resultados",
        loadChildren: () => import("./admin-resultados/admin-resultados.module").then(m => m.AdminResultadosModule)
    }
]

export const routing = RouterModule.forChild(routes)
